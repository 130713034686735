body {
  background-color: #f9f9fc !important;
  margin: 0 !important;
  padding: 0 !important;
}

.app {
  height: 100vh;
  width: 100vw;
  padding: 10px !important;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.app-logo {
  position: absolute;
  top: 40px;
}
.app-logo img {
  width: 115px;
}
.main-container {
  background-color: #ffffff !important;
  margin: 0 auto;
  border-radius: 1rem;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  padding: 2%;
  max-width: 620px !important;
}

.descriptionText {
  font-size: 1rem;
  overflow: hidden;
  text-align: center;
  color: #8e9095;
  padding: 0 10px !important;
  font-weight: 300;
}

.firebaseui-idp-button {
  border: 0 solid #e5e7eb;
  cursor: pointer;
  padding: 0;
  outline: transparent solid 2px;
  outline-offset: 2px;
  line-height: 1.2;
  max-width: none !important;
  background: rgb(0, 0, 0);
  width: 100%;
  margin: auto;
  font-size: 1.125rem !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  min-height: 50px !important;
  border-radius: 5px !important;
  box-shadow: none !important;
}
.firebaseui-idp-button:hover {
  background-color: #333 !important;
}

.firebaseui-card-content {
  max-width: none !important;
}

.firebaseui-container {
  max-width: none !important;
}
.firebaseui-link {
  color: #8e9095 !important;
}

.padded-div {
  padding: 10px;
}

.firebaseui-card-content,
.firebaseui-card-footer {
  margin: 1.5rem auto !important;
  max-width: 360px;
}

.firebaseui-tos {
  margin-bottom: 0px !important;
  display:none !important;
}

.heading-center {
  text-align: center;
  justify-content: center;
  line-height: 190%;
}

.hidden {
  display: none;
}

/** https://stackoverflow.com/questions/23584120/line-before-and-after-title-over-image */
h3.line-through {
  color: #777777;
  font-size: 1rem;
  font-weight: 400;
  margin: 0.7em auto;
  overflow: hidden;
  text-align: center;
  width: 100%;
}

h3.line-through:before,
h3.line-through:after {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  content: "";
  display: inline-block;
  margin: 0 0.5em 0 -55%;
  vertical-align: middle;
  width: 50%;
}

h3.line-through:after {
  margin: 0 -55% 0 0.5em;
}
